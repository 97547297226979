<template>
    <modal ref="modalDuplicarDescuentos" titulo="Duplicar los descuentos a otro cedis" :height="40" no-cancelar cerrar @guardar="duplicar">
        <cargando v-if="cargando" />
        <div class="row mx-0 justify-center">
            <div class="col-8 text-center text-general f-15 mb-2 ">
                Selecciona el cedis al cual se duplicará esta configuración
            </div>
            <div v-for="(cedis,d) in cedisClonar" :key="d" class="col-8 px-4 mb-3 mt-2">
                <el-checkbox v-model="cedis.checked" class="check-dark text-general" :true-label="1" :false-label="0" :disabled="cedis.id === idCedis ? true : false">
                    {{ cedis.nombre }}
                </el-checkbox>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/configurar/admin/descuentosLista';

export default {
    data(){
        return {
            cargando: false,
            cedisClonar: [],
            idCedis: null,
            tipo: null,
        }
    },
    methods: {
        async toggle(cedis){
            // await this.limpiar();

            // this.idCedis = idCedis;
            let cedisClonar = _.cloneDeep(cedis);
            // cedisClonar.map((e, index) => {
            //     if(e.id != idCedis){
            //         e.checked = 0;
            //     	return e;
            //     }
            //     cedisClonar.splice(index, 1);
            // });

            this.cedisClonar = cedisClonar;
            // this.tipo = tipo;
            this.$refs.modalDuplicarDescuentos.toggle();
        },

        async duplicar(){
			this.$refs.modalDuplicarDescuentos.toggle();
        },

        async limpiar(){
        //     this.cargando = false;
        //     this.cedisClonar = [];
        //     this.idCedis = null;
        //     this.tipo = null;
        }
    }
}
</script>

<style>

</style>
