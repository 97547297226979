import axios from 'axios'

const API = 'descuentos'

const ENDPOINTS = {
    getClasificaciones(){
        return axios.get(`${API}/clasificaciones/listar`)
    },
    postClasificacion(payload){
        return axios.post(`${API}/clasificaciones/guardar`, payload)
    },
    deleteClasificacion(id){
        return axios.delete(`${API}/clasificaciones/${id}/eliminar`)
    },
    putClasificacion(id, payload){
        return axios.put(`${API}/clasificaciones/${id}/actualizar`, payload)
    },
    actualizarPosiciones(payload){
        return axios.post(`${API}/clasificaciones/actualizar/posiciones`, payload)
    },
    getClasificacionesCedis(idCedis, params){
        return axios.get(`${API}/clasificaciones-cedis/${idCedis}/listar`,{params})
    },
    getCedisUser(){
        return axios.get(`${API}/cedis-user/listar`)
    },
    getParametros(idLista, params){
        return axios.get(`${API}/${idLista}/parametros`, {params})
    },
    cambioValorParametro(payload){
        return axios.post(`${API}/parametros/cambio`, payload)
    },
    postDescuento(payload){
        return axios.post(`${API}/guardar`, payload)
    },
    putDescuento(idDescuento, payload){
        return axios.post(`${API}/${idDescuento}/actualizar`, payload)
    },
    deleteDescuento(idDescuento){
        return axios.delete(`${API}/${idDescuento}/eliminar`)
    },
    clonarDescuentos(payload){
        return axios.post(`${API}/clonar`, payload)
    },
    updateClasificacionTendero(payload){
        return axios.post(`${API}/cambiar-clasificacion`, payload)
    },
    updateClasificacionClientes(payload){
        return axios.post(`${API}/cambiar-clasificacion/clientes`, payload)
    }
};
export default ENDPOINTS;